.pagination {
  margin-top: 10px;
  margin-bottom: 10px;
}

.flex {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width:100%;
}

.item {
  padding: 10px 15px;
  cursor: pointer;


  background-size: 100% 200%;
  background-image: linear-gradient(to bottom, #fff 50%, black 50%);
  transition: background-position 0.5s;
}

.item:hover {
  background-position: 0 100%;
  color:#fff
}
.item:not(:last-child) {
  margin-right: 10px;
}


.activePag {
  background-color: #000!important;
  color:#fff!important;
}